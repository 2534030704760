<template>
  <div class="invited">
    <div class="dep-search" id="topDiv" :style="{ background: style }">
      <div class="week">
        <div
          class="w-tiems"
          v-for="(item, index) in week"
          :key="index"
          :class="index == active ? 'active' : ''"
          @click="checkActive(item, index)"
        >
          <div>{{ item.weeked }}</div>
          <div class="data">{{ item.time }}</div>
        </div>
      </div>
    </div>

    <div class="meeting-box">
      <div class="m-item" v-for="item in list" :key="item.meetingRoomId">
        <div class="m-title">{{ item.meetingRoomName }}</div>
        <div class="m-box" v-if="item.meetingInfoList.length > 0">
          <div
            class="m-con"
            v-for="(items,index) in item.meetingInfoList"
            :key="index"
          >
            <div class="c-time">{{ items.meetingDateTime }}</div>
            <div class="c-content">{{ items.meetingName }}</div>
          </div>
        </div>
        <div class="m-box" v-else>
          <div class="m-con">暂无会议~</div>
        </div>

        <div class="m-join" @click="linkUrl(item)">预约会议</div>
      </div>
    </div>

    <TabberItem :active="active"></TabberItem>
  </div>
</template>

<script>
import { Toast } from "vant";
import { initMixin } from "@/mixins/initMixin";
import TabberItem from "@/components/TabberItem";
export default {
  name: "index",
  mixins: [initMixin],
  data() {
    return {
      loading: false,
      finished: false,
      current: 0,
      size: 10,

      active: 0,

      list: [],

      week: [],
      arr: [
        { weeked: "周一", name: "1", data: "" },
        { weeked: "周二", name: "2", data: "" },
        { weeked: "周三", name: "3", data: "" },
        { weeked: "周四", name: "4", data: "" },
        { weeked: "周五", name: "5", data: "" },
        { weeked: "周六", name: "6", data: "" },
        { weeked: "周日", name: "0", data: "" },
      ],

      flag: false,
      days: "",

      style: "transparent",
    };
  },
  created() {
    this.getSevenData();
    this.initFn();
  },
  mounted() {
    window.onscroll = this.pageScroll;
  },
  methods: {
    pageScroll() {
      // 获取滚动的距离
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop <= 10) {
        this.style = "transparent";
      } else {
        this.style = "#fff";
      }
    },
    // 会议预约
    linkUrl(item) {
      return this.$router.push({
        path: "/reservation",
        query: {
          meetingRoomName: item.meetingRoomName,
          meetingRoomId: item.meetingRoomId,
          bookableStartTime: item.bookableStartTime,
          bookableEndTime: item.bookableEndTime,
          id:item.id,
          day: this.days,
        },
      });
    },

    // 跳转详情
    linkDetail(item) {
      var json = JSON.stringify(item);
      if (item.receiptStatus == 1 && item.signInStatus == 0) {
        return this.$router.push({
          path: "/signInDetail",
          query: { detail: json },
        });
      } else {
        return this.$router.push({
          path: "/receiptDetail",
          query: { detail: json },
        });
      }
    },

    // 获取会议室和会议
    async getDate() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      let params = {
        conferenceDate: this.days,
      };
      await this.$get(
        `/conference/room/queryConferenceReservationByDate`,
        params
      ).then((res) => {
        if (res.code == "0") {
          Toast.clear();
          this.list = res.result.roomInfoList;
        }
      });
    },

    // 获取日期
    fun_date(aa) {
      var date1 = new Date();
      date1.setDate(date1.getDate() + aa);
      var day2 = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var month =
        date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1;
      var time2 = date1.getFullYear() + "-" + month + "-" + day2;
      return time2;
    },
    // 获取7天日期
    getSevenData() {
      // this.fun_date(6)
      let data = [];
      var date1 = new Date();
      var day1 = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var month =
        date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1;
      var time1 = date1.getFullYear() + "-" + month + "-" + day1; //time1表示当前时间
      this.days = time1;
      for (let i = 0; i < 7; i++) {
        // console.info(this.fun_date(i));
        data.push(this.fun_date(i)); //获得日期插入数组
      }
      // console.info(data)
      var wk = ""; //创建一个变量

      data.forEach((item, index) => {
        var as = item.split("-");
        var times = item;
        var isDay = false;
        if (item == time1) {
          this.active = index;
          isDay = true;
        } else {
          isDay = false;
        }
        this.arr.forEach((val, index) => {
          if (new Date(item).getDay() == val.name) {
            wk = val.weeked;
          }
        });
        this.week.push({
          data: item,
          weeked: wk,
          time: times,
          isDay: isDay,
        });
      });

      this.zj = this.week[0].weeked;
    },

    checkActive(item, index) {
      this.zj = item.weeked;
      this.active = index;
      this.days = item.data;
      this.getDate();
    },
  },
  components: {
    [Toast.name]: Toast,
    TabberItem,
  },
};
</script>

<style lang="scss" scoped>
.invited {
  background: #f4f4f4;
  min-height: 100vh;
}
// .invited-list {
//   overflow: hidden;
// }
.dep-search {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 111;
}
.dep-search .week {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f7f7f7;
  margin: 0 10px;
}
.dep-search .week .w-tiems {
  width: 90px;
  text-align: center;
  font-size: 18px;
  color: #b9b9b9;
  white-space: nowrap;
  flex-shrink: 0;
  background: #ffffff;
  margin-right: 8px;
  padding: 6px 0;
  border-radius: 5px;
  &:last-child{
    margin-right: 0;
  }
} //flex-shrink:0设置固定宽度生效值
.dep-search .week .w-tiems.active {
  color: #fff !important;
  background: linear-gradient(0deg, #526eff 0%, #528fff 100%);
}
.dep-search .week .w-tiems .data {
  font-size: 12px;
}
.dep-search .week .w-tiems.active .data {
  color: #fff;
}

.meeting-box {
  margin: 0 10px 10px;
  .m-item {
    padding: 13px 13px 10px 14px;
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 0px 11px 16px 0px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      width: 5px;
      height: 100%;
      background: #526eff;
      position: absolute;
      left: 0;
      top: 0;
    }

    .m-title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4d4d;
      margin-left: 12px;
      margin-bottom: 17px;
    }
    .m-con {
      background: #f6f8ff;
      border-radius: 5px;
      padding: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #4d4d4d;
      margin-bottom: 4px;
      .c-time {
        font-weight: 500;
        color: #526eff;
        margin-bottom: 6px;
      }
    }
    .m-join {
      width: 76px;
      height: 28px;
      background: linear-gradient(0deg, #e59c45 0%, #ffba67 100%);
      border-radius: 14px;
      text-align: center;
      line-height: 28px;

      font-size: 14px;
      font-weight: 400;
      color: #fefefe;
      position: absolute;
      top: 13px;
      right: 13px;
    }
  }
}
</style>