// import store from '@/store'
import Cookies from "js-cookie";
import config from "@/config";
const initMixin = {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    // 判断是否登录
    initFn() {
      var openid = Cookies.get("openid");
      if (openid == null || openid == "" || openid == "undefined") {
        if (this.code) {
          this.getWeChatOpenId();
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&redirect_uri=${config.backUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
      } else {
        // this.getConferenceUserToken();
        // 判断用户是否绑定
        this.queryConferenceUserIsBind()
      }
    },

    // 根据code获取openId
    async getWeChatOpenId() {
      let params = {
        code: this.code,
      };
      await this.$post(`/h5/weChat/getWeChatOpenId`, params).then((res) => {
        if (res.code == "0") {
          let seconds = 60;//设置过期时间（秒）
          let expires = new Date(new Date() * 1 + seconds * 1000);

          Cookies.set("openid", res.result.openid, { expires: 7 });
          // console.info('获取了openId:'+res.result.openid)
          this.queryConferenceUserIsBind()
        }
      });
    },
    // 根据openId获取token
    async getConferenceUserToken() {
      var openId = Cookies.get("openid");
      let params = {
        openId: openId,
      };
      await this.$post(`/h5/weChat/getConferenceUserToken`, params).then((res) => {
        if (res.code == "0") {
          Cookies.set("tonken", res.result.token);
          this.getDate();
        }
      });
    },
    // 判断用户是否绑定
    async queryConferenceUserIsBind() {
      var openId = Cookies.get("openid");
      let params = {
        openId: openId,
      };
      await this.$post(`/h5/weChat/queryConferenceUserIsBind`, params).then(
        (res) => {
          if (res.code == "0") {
            // console.info("res.result");
            // console.info(res.result);

            if (res.result == "null" || res.result == "" || res.result == null) {
              Cookies.set("userBind", 'yes');
              this.$router.push({ path: "/binding" });
            } else {
              Cookies.remove("userBind");
              if (res.result.userType == '2') {
                this.$router.push({ path: "/index", query: { userType: '2' } });
              } else {
                // 判断本地token 是否存在
                const token = Cookies.get("tonken");
                if (token) {
                  this.getDate();
                } else {
                  // 没有token 获取token
                  this.getConferenceUserToken()
                }
              }

            }
          }
        }
      );
    },
  }
}

export { initMixin }